<template>
    <div class="rb-view">
        <div>
            <el-row>
                <el-col>

                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
    export default {
        name: "video-detail"
    }
</script>

<style scoped>

</style>
