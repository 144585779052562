<template>
    <div class="rb-view">
        <div>
            <div>
                <el-form :ref="search_form_name" :model="search_option" size="mini" inline  label-position="right" label-width="80px">
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="上传时间:" prop="time_range">
                                <el-radio-group v-model="search_option.time_range" @change="handleSearchTap">
                                    <el-radio-button label="all">全部</el-radio-button>
                                    <el-radio-button label="today">今日</el-radio-button>
                                    <el-radio-button label="yesterday">昨天</el-radio-button>
                                    <el-radio-button label="7day">7天内</el-radio-button>
                                    <el-radio-button label="30day">30天内</el-radio-button>
                                    <el-radio-button label="this_month">今月</el-radio-button>
                                    <el-radio-button label="this_year">今年</el-radio-button>
                                </el-radio-group>
                                <span>
                                    <span style="margin-left:20px;margin-right:10px;">自定义:</span>
                                      <el-date-picker @change="handleSearchTap"
                                                      v-model="search_option.time_range"
                                                      type="daterange"
                                                      range-separator="至"
                                                      start-placeholder="开始日期"
                                                      end-placeholder="结束日期">
                                      </el-date-picker>
                                </span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-rol>
                            <el-form-item label="ID:" prop="id"><el-input v-model="search_option.id" clearable></el-input></el-form-item>
                            <el-form-item label="场地:" prop="playground_id">
                                <el-select v-model="search_option.playground_id" filterable placeholder="" clearable>
                                    <el-option v-for="(p) in params.playground" :key="p.key" :label="p.value" :value="p.key"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-rol>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <span style="white-space: nowrap">
                              <el-button type="primary" icon="el-icon-search" size="mini" style="margin-left: 20px;" @click="handleSearchTap">查询</el-button>
                              <el-button icon="el-icon-delete" size="mini" @click="handleSearchClear(search_form_name)">清空</el-button>
                            </span>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div style="margin-top:16px;">
                <el-table :data="page_list" size="mini" border style="width:100%">
                    <el-table-column label="ID" prop="id" width="150"></el-table-column>
<!--                    <el-table-column label="地址" prop="url"></el-table-column>-->
<!--                    <el-table-column label="场地ID" prop="playground_id"></el-table-column>-->
                    <el-table-column label="视频" width="128px" align="center">
                        <template #default="scope">
                            <el-image style="width: 100%;cursor: pointer" fit="cover" :src="videoImgHost + scope.row.url +`?vframe/jpg/offset/${Number.parseInt(scope.row.seconds/2)}`" @click="onPlayTap(scope.row)"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column label="场地标题" prop="playground_title"></el-table-column>
                    <el-table-column label="场地编号" prop="playground_code"></el-table-column>
                    <el-table-column label="场地ID" prop="playground_id"></el-table-column>
                    <el-table-column label="视角" prop="view" width="90"></el-table-column>
                    <el-table-column label="时长" prop="time_tip" width="60"></el-table-column>
                    <el-table-column label="时间段" prop="date_time_span" width="168"></el-table-column>
                    <el-table-column label="上传时间" prop="created_at" width="136" :formatter="elTimeStampFormat"></el-table-column>
<!--                    <el-table-column label="操作" width="100">-->
<!--                        <template #default="scope">-->
<!--                            <el-button type="text" size="mini" style="margin-right:8px" @click="onViewTap(scope.row)">详情</el-button>-->
<!--                            <el-dropdown trigger="click" size="mini">-->
<!--                                <span class="el-dropdown-link" style="color:#409EFF;">更多<i class="el-icon-arrow-down"></i>-->
<!--                                </span>-->
<!--                                <template #dropdown>-->
<!--                                    <el-dropdown-menu>-->
<!--                                        <el-dropdown-item>删除</el-dropdown-item>-->
<!--                                    </el-dropdown-menu>-->
<!--                                </template>-->
<!--                            </el-dropdown>-->
<!--                        </template>-->
<!--                    </el-table-column>-->
                </el-table>
                <div style="margin-top:20px;margin-bottom: 0px; text-align:right;">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="page_option.page"
                            :page-sizes="[5,10, 20, 30, 50,100,200]"
                            :page-size="page_option.size"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="page_option.total"
                    ></el-pagination>
                </div>
            </div>
        </div>

        <el-dialog v-model="dialogFormVisible" :title="dialogFormTitle" @close="onTellDialogClose" width="600px" :close-on-click-modal="false" :fullscreen="false" :destroy-on-close="true">
            <video-detail :action="dialogFormAction" :row-id="dialogFormRowId" @onTellDialogClose="onTellDialogClose"></video-detail>
        </el-dialog>

        <el-dialog v-model="dialogVideoVisible" :title="dialogVideoTitle" @close="onTellDialogClose"  :close-on-click-modal="false" :fullscreen="false" :destroy-on-close="true">
            <video :src="videoHost + dialogVideoUrl" controls autoplay style="width:100%"></video>
        </el-dialog>
    </div>
</template>

<script>
    import VideoDetail from './video-detail'
    import {getParams} from "@/api/hado/param";
    import {getList, remove} from "@/api/hado/video";
    import {formatDate, formatTimestamp} from "@/utils/time";
    const param_list=['playground_type','playground']
    export default {
        name: "video-list",
        components:{VideoDetail},
        data(){
            return{
                videoImgHost:'http://hado.image.banbanapi.com/',
                videoHost:'http://hado.video.banbanapi.com/',
                dialogFormTitle:'',
                dialogFormVisible:false,
                dialogFormRowId:0,
                dialogFormAction:'',//create view edit

                dialogVideoVisible:false,
                dialogVideoTitle:'',
                dialogVideoUrl:'',

                loading:false,
                search_form_name:'coach_search',
                page_option:{page:1,size:30,total:0},
                search_option:{time_range:'all'},
                page_list:[],
                params:{playground_type:[],},
                params_map_pure:{},
                params_map:{recharge_type:{},},
            }
        },
        created(){
            this.initPage()
        },
        methods: {
            initPage() {
                this.initParams().then(() => {
                    this.getPageList({page: 1, size: this.page_option.size}, Object.assign({}, this.search_option))
                })
            },
            reloadPage() {
                this.getPageList(this.page_option, this.search_option)
            },
            initParams() {
                return new Promise((resolve, reject) => {
                    getParams(param_list.join(','), 'en').then(resp => {
                        this.params = resp.data;

                        let tmp_params_map = {}
                        let tmp_params_map_pure = {}
                        let tmp_params_map_reverse = {}

                        //这里再这里一下每组参数做成kv方便显示
                        let p_keys = Object.keys(this.params)
                        p_keys.forEach(pl => {
                            console.log('pl:', pl)
                            let tmp_map = {}
                            let tmp_map_pure = {}
                            let tmp_map_reverse = {}
                            this.params[pl].forEach(pm => {
                                // tmp_map[pm.key]=pm.value
                                tmp_map_pure[pm.key] = pm.value
                                tmp_map[pm.key] = {key: pm.key, value: pm.value, color: pm.color}

                                //再调转处理一下 导入Excel参数解析用
                                tmp_map_reverse[pm.value] = pm.key
                            })
                            tmp_params_map[pl] = tmp_map
                            tmp_params_map_pure[pl] = tmp_map_pure
                            tmp_params_map_reverse[pl] = tmp_map_reverse
                        })

                        this.params_map = tmp_params_map
                        this.params_map_pure = tmp_params_map_pure
                        this.params_map_reverse = tmp_params_map_reverse
                        // console.log('params_map:',this.params_map)
                        // console.log('params_map_reverse:',this.params_map_reverse)
                        resolve(resp)
                    }).catch((err) => {
                        if (err) {
                            console.error(err)
                        }
                        reject(err)
                    })
                })
            },
            getPageList(pageOpt, searchOpt) {
                this.loading = true;
                getList(pageOpt.page, pageOpt.size, searchOpt).then(resp => {
                    this.loading = false;
                    this.page_list = resp.data.records;
                    this.page_option.page = pageOpt.page;
                    this.page_option.total = resp.data.total;
                    this.page_option.size = pageOpt.size;
                }).catch(() => {
                    this.loading = false;
                })
            },
            handleSearchTap() {
                let searchOpt = Object.assign({}, this.search_option)
                console.log(searchOpt.time_range)
                if(this.search_option.time_range&&Array.isArray( this.search_option.time_range)){
                    searchOpt.time_range = formatDate(searchOpt.time_range[0])+'||'+formatDate( searchOpt.time_range[1])
                }
                this.getPageList({page: 1, size: this.page_option.size}, searchOpt)
            },
            handleSearchClear(formName) {
                this.$refs[formName].resetFields();
                this.initPage()
            },
            handleSizeChange(pageSize) {
                this.getPageList({page: 1, size: pageSize}, this.search_option);
            },
            handleCurrentChange(pageIndex) {
                this.getPageList({page: pageIndex, size: this.page_option.size}, this.search_option)
            },
            onViewTap(row) {
                console.log('onViewTap:', row)
                this.dialogFormRowId = row.id
                this.dialogFormAction = 'view'
                this.dialogFormVisible = true
            },
            onEditTap(row) {
                console.log('onEditTap:', row)
                this.dialogFormAction = 'edit'
                this.dialogFormRowId = row.id
                this.dialogFormVisible = true
            },
            onCreateTap() {
                console.log('onCreateTap')
                this.dialogFormRowId = 0;
                this.dialogFormAction = 'add'
                this.dialogFormVisible = true
            },
            onRemoveTap(row) {
                this.$messageBox.confirm('确定删除当前数据?', '提示', {
                    cancelButtonText: '取消',
                    confirmButtonText: '确定'
                }).then(() => {
                    remove(row.id).finally(() => {
                        this.reloadPage()
                    })
                })
            },
            onTellDialogClose() {
                this.dialogFormVisible = false;
                this.dialogFormRowId = 0;
                this.reloadPage()
            },
            elTimeStampFormat(row,column,val){
                return formatTimestamp(val)
            },
            onPlayTap(row){
                this.dialogVideoTitle= `${row.playground_code} || ${row.playground_title} ${row.date_time_span}`
                this.dialogVideoUrl = row.url
                this.dialogVideoVisible = true
            }
        }
    }
</script>

<style scoped>

</style>
